<template>
  <div class="header-wrapper">
    <div class="left">
      <div
        @click="onBack"
        class="back">
        <van-icon class="arrow" v-if="type === 'back'" name="arrow-left" />
        <span v-else>＋</span>
      </div>
    </div>
    <div class="title">
      {{ title }}
    </div>
    <div class="right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      default: '',
      type: String
    },
    type: {
      type: String,
      default: 'back'
    },
    back: {
      default: () => {},
      type: Function
    }
  },
  data () {
    return {

    }
  },
  computed: {

  },
  methods: {
    onBack () {
      this.back && this.back()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .header-wrapper {
    display: flex;
    align-items: center;
    height: 45px;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(138, 149, 158, 0.08);
    font-size: 16px;
    font-weight: 500;
    color: #3D3D3D;
    & .left, & .right {
      width: 30%;
    }
    & .back {
      padding: 0px 12px;
      cursor:pointer;
      & span {
        display: inline-block;
        font-size: 22px;
        text-align: center;
        transform: rotate(45deg);
      }
    }
    & .title {
      flex: 1;
      text-align: center;
      font-size: 16px;
      color: rgba(61, 61, 61, 1);
      text-overflow: ellipsis;
      white-space:nowrap;
      overflow:hidden;
    }
    & .right {
      text-align: right;
    }
  }
  .arrow {
    font-size: 20px;
  }
</style>
