<template>
  <div class="all">
    <CommonHeader
    title="选择药品"
    :back="onBack">
    </CommonHeader>
    <div class="select">
      <img style=" width: 16px;height: 16px; margin-right:6px;" src="../../../../assets/img/new-img/search.png" >
      <input placeholder="请输入药品" class="selectContent" v-model="valueStr"/>
      <img @click="searchClose" style=" width: 16px;height: 16px;" src="../../../../assets/img/icon-close.png" >
    </div>
    <ul class="content" v-if="filterList .length >0">
      <li
        :class="['select-item', {selected: i.medicine_id === selectItem.medicine_id}]"
        @click="selectMedicine(i)"
        :key="key"
        v-for="(i,key) in filterList">
        <div
            class="list label">{{ i.medicine_name }}</div>
          <div
            class="list"
            style="width: 18px;"
          >
            <img
              v-if="i.medicine_id === selectItem.medicine_id"
              class="the-img"
              src="../../../../assets/img/icon-mulselect.png">
          </div>
      </li>
    </ul>
    <div v-if="filterList.length === 0" class="content">
      <div class="prompt">
        暂未搜索到“{{valueStr}}”相关内容
      </div>
      <div
        @click="onBack"
        class="unline">使用手输药名</div>
    </div>
     <Model
      @close="close"
      @comfire="comfire"
      v-if="toast"
      :toast.sync="toast"
      :value="''"
      :title="`“${valueStr}”？`"
    />
  </div>
</template>
<script>
import CommonHeader from '../../../../components/CommonHeader'
import Model from './Model'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  data () {
    return {
      data: {},
      valueStr: '',
      timer: null,
      filterList: [],
      toast: false,
      selectItem: {}
    }
  },
  components: {
    CommonHeader,
    Model
  },
  created () {
    this.filterList = this.list
    this.selectItem.medicine_id = this.$route.query.id
  },
  computed: {
    ...mapState('medicine', {
      medicineList: state => state.medicineList
    }),
    list () {
      return this.medicineList
    },
  },
  watch: {
    valueStr (val) {
      let value = val.replace(/(^\s*)|(\s*$)/g, '')
      let list = JSON.parse(JSON.stringify(this.list))
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        if (value) {
          this.filterList = list.filter(i => {
            return i.medicine_name.indexOf(value) >= 0
          })
        } else {
          this.filterList = list
        }
      }, 100)
    },
    list (val) {
      this.filterList = val
    }
  },
  methods: {
    ...mapMutations('medicine', [
      'saveMedicine'
    ]),
    ...mapActions('medicine', [

    ]),
    onBack() {
      if(!this.valueStr) {
        this.$router.go(-1)
      } else if (this.valueStr && this.filterList.length === 0) {
        this.toast = true
      }
    },
    selectMedicine (i) {
      this.selectItem = i
      this.saveMedicine({payload:i})
      this.$router.go(-1)
    },
    close() {
      this.toast = false
      this.$router.go(-1)
    },
    comfire(){
      this.toast = false
      this.saveMedicine({payload:{medicine_name: this.valueStr, medicine_id: ''}})
      this.$router.go(-1)
    },
    searchClose () {
      this.valueStr = ''
    }
  }
}
</script>
<style scoped>
  .all {
    height: 100%;
    display: flex;
    flex-direction: column;
    & .content {
      flex: 1;
      overflow: auto;
      color: #242938;
      padding:0 18px;
      border-top: 1px solid rgba(235, 237, 240, 1);
      & .select-item {
        display: flex;
        background:rgba(255,255,255,1);
        border-bottom: 1px solid rgba(235, 237, 240, 1);
        padding:14px 0;
      }
    }
  }
  .selectContent {
    width: 100%;
    background:rgba(248,248,248,1);
    &::placeholder {
      color:#ccc;
      -webkit-text-fill-color: #ccc;
    }
  }
  .select {
    height: 36px;
    background: #F7F8FA;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin: 10px 18px;
  }
  .selected {
    color:rgba(24, 144, 255, 1)
  }
.the-img {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}
.list {
  display: flex;
  align-items: center;
}
.label {
  flex: 1;
}
.prompt {
  text-align: center;
  padding-top: 28px;
  font-size:16px;
  font-weight:bold;
  color:rgba(153,153,153,1);
  line-height:24px;
}
.unline {
  text-align: center;
  font-size:16px;
  font-weight:bold;
  color:rgba(60,130,255,1);
  line-height:24px;
  padding-top: 28px;
  text-decoration:underline;
}
</style>
